(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/national-suspension-message/assets/javascripts/national-suspension-message.js') >= 0) return;  svs.modules.push('/components/accountservices/national-suspension-message/assets/javascripts/national-suspension-message.js');
(function(svs) {
  svs = svs || {};
  svs.accountservices = svs.accountservices || {};
  svs.accountservices.national_suspension_message = svs.accountservices.national_suspension_message || {};
  var logger = svs.core.log.getLogger('accountservices:national-suspension-message');

  function showErrorDialog(message, title, callback) {
    var cb = callback ?
            function() {
              callback.call();
              dialog.close();
            } :
            undefined;
    var dialog = new svs.ui.dialog.Confirm({
      branding: svs.ui.dialog.branding.TUR,
      icon: 'exclamation-sign',
      type: svs.ui.dialog.type.ERROR,
      allowDimmerClick: false,
      title: title || 'Nationell Spelpaus',
      message: [
        {
          type: svs.ui.dialog.message.TEXT,
          text: message
        }
      ],
      actions: [
        {
          title: 'Okej',
          callback: cb
        }
      ]
    });
    svs.ui.dialog.api.add(dialog);
  }

  function showNationalSuspensionMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register.');

    var message = svs.accountservices.national_suspension_message.templates.message();
    showDialog(message, callback);
  }

  function showNationalSuspensionPayMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to pay.');

    var message = svs.accountservices.national_suspension_message.templates.pay_message();
    showDialog(message, callback);
  }

  function showNationalSuspensionDemoTicketMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to use a demo ticket.');

    var message = svs.accountservices.national_suspension_message.templates.demo_ticket_message();
    showDialog(message, callback);
  }

  function showNationalSuspensionDepositMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to make a deposit.');

    var message = svs.accountservices.national_suspension_message.templates.deposit_message();
    showDialog(message, callback);
  }

  function showNationalSuspensionCreateSyndicateMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to create syndicate.');

    var message = svs.accountservices.national_suspension_message.templates.create_syndicate_message();
    showDialog(message, callback);
  }

  function showNationalSuspensionCreateSyndicateMessageNew(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to create syndicate. With the new design!');

    var message = svs.accountservices.national_suspension_message.templates.create_syndicate_message_new();
    showErrorDialog(message, 'Kunde inte skapa lag', callback);
  }

  function showNationalSuspensionSyndicateMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to do some activities for syndicate.');

    var message = svs.accountservices.national_suspension_message.templates.syndicate_message();
    showDialog(message, callback);
  }

  function showNationalSuspensionAcceptInviteSyndicateMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to accept invitation for syndicate.');

    var message = svs.accountservices.national_suspension_message.templates.accept_invite_syndicate_message();
    showDialog(message, callback);
  }

  function showNationalSuspensionPlaySyndicateMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to play syndicate games.');

    var message = svs.accountservices.national_suspension_message.templates.syndicate_play_message();
    showDialog(message, callback);
  }

  function showNationalSuspensionVoucherMessage(callback) {
    logger.debug('Shows suspension message for user suspended in the national suspension register while trying to to claim Triss voucher.');

    var message = svs.accountservices.national_suspension_message.templates.voucher_message();
    showDialog(message, callback);
  }

  function showDialog(message, callback) {
    var cb = callback ?
        function() {
          callback.call();
          dialog.close();
        } :
        undefined;
    var dialog = new svs.ui.dialog.Confirm({
      branding: svs.ui.dialog.branding.TUR,
      icon: 'spelpaus',
      type: svs.ui.dialog.type.INFO,
      allowDimmerClick: false,
      title: 'Nationell Spelpaus',
      message: [
        {
          type: svs.ui.dialog.message.TEXT,
          text: message
        }
      ],
      actions: [
        {
          title: 'Okej',
          callback: cb
        }
      ]
    });
    svs.ui.dialog.api.add(dialog);
  }

  svs.accountservices.national_suspension_message.showNationalSuspensionMessage = showNationalSuspensionMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionPayMessage = showNationalSuspensionPayMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionDemoTicketMessage = showNationalSuspensionDemoTicketMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionDepositMessage = showNationalSuspensionDepositMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionCreateSyndicateMessage = showNationalSuspensionCreateSyndicateMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionCreateSyndicateMessageNew = showNationalSuspensionCreateSyndicateMessageNew;
  svs.accountservices.national_suspension_message.showNationalSuspensionSyndicateMessage = showNationalSuspensionSyndicateMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionAcceptInviteSyndicateMessage = showNationalSuspensionAcceptInviteSyndicateMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionPlaySyndicateMessage = showNationalSuspensionPlaySyndicateMessage;
  svs.accountservices.national_suspension_message.showNationalSuspensionVoucherMessage = showNationalSuspensionVoucherMessage;
})(svs);


 })(window);